// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  defaultauth: 'springSecurity',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  keycloak: {
    server : "http://127.0.0.1:8891",
    realm : "ccmonline",
    client_id : "ccm-cbs-client",
    client_secret : "95e2339f-1867-4571-996f-4baed317bf4c",
    grant_type : {
      password : "password",
      refresh_token : "refresh_token"
    },
    session_token : "session_token",
    session_refresh_token : "session_refresh_token",
    session_user : "session_user"
  },
  springSecurity: {
    server : "https://ticket.alutole.com",
    session_token: "token",
    session_refresh_token : "expireDate",
    session_user : "user"
  },
  apiUrl : "https://ticket.alutole.com"
};


export const keycloak = {
  server : "http://127.0.0.1:8891",
  realm : "ccmonline",
  client_id : "ccm-cbs-client",
  client_secret : "95e2339f-1867-4571-996f-4baed317bf4c",
  grant_type : {
    password : "password",
    refresh_token : "refresh_token"
  },
  session_token : "session_token",
  session_refresh_token : "session_refresh_token",
  session_user : "session_user"

};


export const api = {
  auth : {
    spring : {
      login: environment.springSecurity.server + '/mobile/auth/connect',
      logout: environment.springSecurity.server + '/mobile/auth/logout'
    },
    token :  keycloak.server+ '/auth/realms/'+keycloak.realm + '/protocol/openid-connect/token',
    refresh_token :  keycloak.server+ '/auth/realms/'+keycloak.realm + '/protocol/openid-connect/token',
    logout : keycloak.server+ '/auth/realms/'+keycloak.realm + '/protocol/openid-connect/logout',
    instrospect : keycloak.server+ '/auth/realms/'+keycloak.realm + '/protocol/openid-connect/token/introspect',
    info : keycloak.server+ '/auth/realms/'+keycloak.realm + '/protocol/openid-connect/userinfo',
  },

  quiz : {
      categorie : {
        list : environment.apiUrl + '/mobile/quiz/categories/',
        save : environment.apiUrl + '/mobile/quiz/categories/save'
      },
      theme : {
        list : environment.apiUrl + '/mobile/quiz/themes/',
        save : environment.apiUrl + '/mobile/quiz/themes/save'
      },
      question : {
        list : environment.apiUrl + '/mobile/quiz/questions/',
        save : environment.apiUrl + '/mobile/quiz/questions/save'
      },
      answer : {
        list : environment.apiUrl + '/mobile/quiz/answers/',
        save : environment.apiUrl + '/mobile/quiz/answers/save'
      },
      price : {
        list : environment.apiUrl + '/mobile/quiz/prices/',
        picture: environment.apiUrl + '/mobile/quiz/prices/picture',
        save : environment.apiUrl + '/mobile/quiz/prices/save'
      },
      parameter : {
        list : environment.apiUrl + '/mobile/quiz/parameters/',
        save : environment.apiUrl + '/mobile/quiz/parameters/save'
      }
  },

  account : {
    matricule :  environment.apiUrl + '/accounts/customer',
    otp :  environment.apiUrl + '/otp/account'
  },

  package : {
    all :  environment.apiUrl + '/packages',
    active :  environment.apiUrl + '/packages/active'
  },
  customers : {
    save :  environment.apiUrl + '/customers/backoffice/save',
    update :  environment.apiUrl + '/customers/backoffice/update',
    list :  environment.apiUrl + '/customers/backoffice',
    donwload : environment.apiUrl + "/customers/backoffice",
    downloadMatricule : environment.apiUrl + "/customers/backoffice",
    upload : environment.apiUrl + "/customers/backoffice",
    updatePackage : environment.apiUrl + "/customers/backoffice",
    updatePassword : environment.apiUrl + "/customers/backoffice",
    enableAccount : environment.apiUrl + "/customers/backoffice"
  },
  users: {
    list: environment.apiUrl + '/users',
    create: environment.apiUrl + '/users',
    update: environment.apiUrl + '/users',
    id: environment.apiUrl + '/users',
    password: environment.apiUrl + '/users/password/update',
  },
  limitTrxAccount : {
    all :  environment.apiUrl + '/configs/accounts/limits'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
