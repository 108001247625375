import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { of, BehaviorSubject, throwError } from "rxjs";
import { environment, api } from '../../../environments/environment';
import { IProfil, Profil } from "src/app/shared/models/profil.model";
import * as moment from 'moment';

interface IMobileLoginResponse{
  success:boolean;
  msg:string;
  data:IProfil;
}

@Injectable({
  providedIn: "root",
})
export class SpringAuthService {
  isAuthenticated: Boolean;
  user: IProfil = null;
  user$ = new BehaviorSubject<IProfil>(this.user);
  signingIn: Boolean;
  return: string;

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
  };


  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/');
  }


  public signin(username, password) {
    console.log(username, password);
    this.signingIn = true;
    let authPayLoad = new HttpParams()
      .set("phone", username)
      .set("pin", password)
      .set("isCustomer", true);

    return this.http.post(`${api.auth.spring.login}`, authPayLoad, this.options)
      .pipe(
        map((res: IMobileLoginResponse) => this.convertDateFromServer(res)),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }


  public refreshToken() {
    /*
    let refreshTokenPayLoad = new HttpParams()
      .set("username", this.getUser().email)
      .set("client_id", `${environment.springSecurity.client_id}`)
      .set("refresh_token", this.getRefreshToken())
      .set("client_secret" ,`${environment.springSecurity.client_secret}`)
      .set("grant_type" ,`${environment.springSecurity.grant_type.refresh_token}`);


    return this.http.post(`${api.auth.refresh_token}`, refreshTokenPayLoad, this.options )
      .pipe(
        map((res: any) => {
          this.setUserAndToken(res.access_token, res.refresh_token, true );
          this.signingIn = false;
          return res;
        }),
        catchError((error) => {
          return of(error);
        })
      ).subscribe(data => {}, error => {});
        */
  }

  public signout() {
    let authPayLoad = new HttpParams();
  
    this.http.post(`${api.auth.spring.logout}`, authPayLoad, this.options )
      .pipe(
        map((res: any) => {
          this.ls.clear();
          this.user$.next(null);
          this.router.navigateByUrl("login");
          return res;
        }),
        catchError((error) => {
          return of(error);
        })
      ).subscribe();
   


  }


  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  isDisabled(): boolean {
    return true;
  }

  getJwtToken() : string {
    return this.ls.getItem(`${environment.springSecurity.session_token}`);
  }

  getRefreshToken() : string {
    return this.ls.getItem(`${environment.springSecurity.session_refresh_token}`);
  }
  getUser() : IProfil {
    if (!this.ls.getItem(`${environment.springSecurity.session_user}`)) {
      return null;
    }
    return this.ls.getItem(`${environment.springSecurity.session_user}`);
  }

  setUserAndToken(res: IMobileLoginResponse) {
    console.log(res);
    this.isAuthenticated = res.success;
    const copy: IProfil = {... res.data};
    this.user = copy;
    console.log("USER"); 
    console.log(this.user);
    this.user$.next(this.user);
    this.ls.setItem(`${environment.springSecurity.session_token}`, res.data.token)
    this.ls.setItem(`${environment.springSecurity.session_refresh_token}`, res.data.expireDate)
    this.ls.setItem(`${environment.springSecurity.session_user}`, this.user)
  }

  clearUser(){
    this.isAuthenticated = false;
    this.user$.next(null);
    this.ls.clear();
  }

  hasAnyRoles(requiredRoles: string[]): boolean {
    let hasRole = true;
    //return true //temp
    /*
    let connectedUser : ProfilData  = new ProfilData(this.getUser());
    requiredRoles.forEach(roleId => {
      if(connectedUser.roles){
        connectedUser.roles.forEach(group => {
          if(group == roleId){
            hasRole = true
          }
        })
      }
    });*/
    return hasRole;
  }

  protected convertDateFromServer(res: IMobileLoginResponse): IMobileLoginResponse {
    console.log("FROM SERVEUR");
    console.log(res);   
    if (res.data) {     
      res.data.creationDate = res.data.creationDate ? moment(res.data.creationDate) : undefined;
      res.data.expireDate = res.data.expireDate ? moment(res.data.expireDate) : undefined;
    }
    this.setUserAndToken(res);
    this.signingIn = false;    
    return res;
  }
}
