import { MenuItem } from './menu.model';
import {config} from '../../../config'
export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'menu.title',
        isTitle: true,
        roles: config.authRoles.user,
    },
    {
        id: 2,
        label: 'menu.dashboard',
        icon: 'bx-home-circle',
        roles: config.authRoles.user,
        link: '/dashboard',
    },
    {
        id: 1,
        label: 'menu.administration',
        isTitle: true,
        roles: config.authRoles.admin,
    },
    {
        id: 2,
        label: 'menu.administration.categories',
        icon: 'bx-box',
        roles: config.authRoles.admin,
        link: '/admin/categorie',
    }
    ,
    {
        id: 2,
        label: 'menu.administration.themes',
        icon: 'bxl-dropbox',
        roles: config.authRoles.admin,
        link: '/admin/theme',
    }
    ,
    {
        id: 2,
        label: 'menu.administration.questions',
        icon: 'bx-question-mark',
        roles: config.authRoles.admin,
        link: '/admin/question',
    }
    ,
    {
        id: 2,
        label: 'menu.administration.answers',
        icon: 'bx-checkbox-checked',
        roles: config.authRoles.admin,
        link: '/admin/answer',
    },
    {
        id: 2,
        label: 'menu.administration.prices',
        icon: 'bxs-component',
        roles: config.authRoles.admin,
        link: '/admin/price',
    }
];

