import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';
import { JwtAuthService } from "../services/jwt-auth.service";

import { environment, api } from '../../../environments/environment';
import { SpringAuthService } from '../services/spring-auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
      private springAuth: SpringAuthService,
      private authenticationService: AuthenticationService,
      private authfackservice: AuthfakeauthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
      if (environment.defaultauth === 'springSecurity') {
        var token = this.springAuth.getJwtToken();
        const { url, method, headers, body } = request;
    
        var isLogoutReq = url === `${api.auth.spring.logout}`
        var isLoginReq = url === `${api.auth.spring.login}`

        if (token && !isLogoutReq && !isLoginReq) {
          request = request.clone({
            setHeaders: {
              Authorization: `${token}`
            },
          });
        }else {
          request = request
        }
      }else if (environment.defaultauth === 'firebase') {
            const currentUser = this.authenticationService.currentUser();
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.token}`
                    }
                });
            }
        } else {
            // add authorization header with jwt token if available
            const currentUser = this.authfackservice.currentUserValue;
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.token}`
                    }
                });
            }
        }
        return next.handle(request);
    }
}
