import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';
import {JwtAuthService} from "../services/jwt-auth.service";
import { SpringAuthService } from '../services/spring-auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private springAuth: SpringAuthService,
        private authenticationService: AuthenticationService,
        private authFackservice: AuthfakeauthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const roles = route.data['roles'];
        let hasRole = true;
        if(environment.defaultauth === 'springSecurity'){
          if (this.springAuth.isLoggedIn()) {
            if (!roles || roles.length === 0) {
              return true;
            }
            console.log(roles);
            if(this.springAuth.hasAnyRoles(roles)){
              return true;
            }else{
              hasRole = false;
            }
          }
        } else if (environment.defaultauth === 'firebase') {
            const currentUser = this.authenticationService.currentUser();
            if (currentUser) {
                // logged in so return true
                return true;
            }
        } else {
            const currentUser = this.authFackservice.currentUserValue;
            if (currentUser) {
                // logged in so return true
                return true;
            }
        }
        // not logged in so redirect to login page with the return url
        console.log(hasRole);
        if(!hasRole) this.router.navigate(['404'])
        else this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
