export const config = {
  emailPattern:'^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
  authRoles: {
    sa: ['sa'], // Only Super Admin has access
    admin: ['sa', 'admin'], // Only SA & Admin has access
    editor: ['sa', 'admin', 'editor'], // Only SA & Admin & Editor has access
    user: ['sa', 'admin', 'editor', 'user'], // Only SA & Admin & Editor & User has access
    guest: ['sa', 'admin', 'editor', 'user', 'guest'] // Everyone has access
  }
}
